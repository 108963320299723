@charset "UTF-8";
@use "./common" as *;
@import "../vendors/normalize";
@import "../vendors/lite-youtube";

// RUWT Ribbon
.tsn-score-ribbon {
    .metabet-scorestrip {
        font-size: rem(11px) !important;
        border-right: none;
        border-left: none;
        border-top-width: 0;

        .metabet-scorestrip-league {
            padding: 0 10px;
        }

        #metabet-scorestrip-league {
            cursor: pointer;
        }

        &:empty {
            display: none;
        }
    }

    .metabet-scorestrip-game {
        .line1,
        .line2 {
            display: none;
        }
    }

    .metabet-scorestrip-sideodd-bet {
        display: none;
    }

    @include tablet {
        padding: unset;

        &.overlay:after {
            display: none;
        }
    }
}

html,
body {
    font-family: $roboto-font;
    background: $white-color;
    color: $text-color-default;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include tablet {
        -webkit-overflow-scrolling: touch !important;
    }
}

.layout-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

img {
    width: 100%;
    height: auto;
    border: 0;
}

.page-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 480px) {
        margin-bottom: 10px;
    }
}

h1.page-title {
    font-family: $header-font;
    font-size: rem(30px);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(37px);

    @media screen and (max-width: 768px) {
        font-size: rem(27px);
    }

    @media screen and (max-width: 475px) {
        font-size: rem(16px);
        position: relative;
        display: inline-block;

        &:after {
            content: "";
            height: 6px;
            width: 100%;
            background: $red-color;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: all 0.3s linear;
            box-sizing: border-box;
        }
    }
}

// SIB NBA
.nba-scoreboard-container {
    margin: 0;
    background: $white-color;

    @include tablet {
        padding: unset;
    }
}

.nba-scoreboard-wrapper {
    width: 100%;
    height: 100px;

    .nba-scoreboard-iframe {
        border: none;
        height: 100px;
        width: 100%;
    }
}

// Open Trust
.ot-floating-button__open svg {
    display: inline;
}

/* End TSN-1630 */
.tsn-advertising-section {
    .more-articles-section {
        .list-item img {
            max-height: initial;
        }
    }
}

.overlay {
    position: relative;
}

.overlay:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #221f20b8;
    z-index: 12;
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
}

.max-w-default {
    max-width: 1100px;
}

.link-mobile-nosso-futebol-assistir {
    display: none;

    @include tablet {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        background-color: #000000;

        .bg-mobile-nosso-futebol-assistir {
            @include mobile-nosso-futebol-assistir;
        }
    }
}
