/*
* Description: Transfer px to rem (we need to avoid of using font-size in pixels)
*/
@use "sass:math";

@function rem($px, $base: 16px) {
    @return math.div($px, $base) * 1rem;
}

/*
* Description: Supported Dimensions for Devices
*/

@mixin mobile {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin default-container {
    @media (max-width: 1100px) {
        @content;
    }
}

/*
* Description: place for css variables (we need to use them to simplify theme customization)
*/

/* File Paths */

/* Colours */
$white-color: #ffffff;
$red-color: #ef3340;
$blue-color: #0033a0;
$text-color-default: #434444;
$tsn-branding-color: #cf0915;
$aubergine-color: #221f20;
$footer-text-color: #5f5f5f;
$footer-bg: #f9f9f9;
$primary-menu-delimiter: #707070;
$secondary-menu-border: #d8d8d8;
$secondary-menu-color: #231f20;

/* Fonts */
$roboto-font: "Roboto", sans-serif;
$header-font: $roboto-font;

/*
* Description: add minxins here (if we are going to use some)
*/

@mixin primary-menu-common {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@mixin hamburger-ico($color) {
    background-image: url("../../images/content/icon-hamburger.svg");
    filter: $color;
}

@mixin tsn-mobile-ico($color) {
    background-image: url("../../images/content/icon-tsn-mobile.svg");
    filter: $color;
}

@mixin ico-bg($size, $pos) {
    background-repeat: no-repeat;
    background-size: $size;
    background-position: $pos;
}

@mixin footer-tsn-logo() {
    background-image: url("../../images/tsn-logo-footer.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin favicon-ico() {
    background-image: url("../../images/favicon.ico");
}

@mixin right-chevron-ico($color) {
    background-image: url("../../images/content/icon-right-chevron.svg");
    filter: $color;
}

@mixin down-chevron-ico($color) {
    background-image: url("../../images/content/icon-down-chevron.svg");
    filter: $color;
}

@mixin arrow-down-ico {
    background-image: url("../../images/content/icon-arrow-down.svg");
}

/* TODO: Check & Remove if not yet being used */
@mixin search-ico {
    background-image: url("../../images/content/icon-search.svg");
}

/* TODO: Check & Remove if not yet being used */
@mixin user-ico {
    background-image: url("../../images/content/icon-user.svg");
}

/* ----------------------------- START: Social Media Mixins ------------------------------ */
@mixin social-media-icon($brand) {
    background-image: url("../../images/social-media/logo-#{$brand}.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #232200;
    background-size: 60%;
}

/* ------------------------------ END: Social Media Mixins --------------------------------- */

/* ----------------------------- START: Edition Flag Mixins --------------------------------- */
@mixin usa-flag-ico {
    background-image: url("../../images/flags/icon-use-flag.svg");
}

@mixin canadian-flag-ico {
    background-image: url("../../images/flags/icon-canadian-flag.svg");
}

@mixin australian-flag-ico {
    background-image: url("../../images/flags/icon-australian-flag.svg");
}

@mixin brazil-flag-ico {
    background-image: url("../../images/flags/icon-brazil-flag.svg");
}

@mixin japan-flag-ico {
    background-image: url("../../images/flags/icon-japanese-flag.svg");
}

@mixin hong-kong-flag-ico {
    background-image: url("../../images/flags/icon-hongkong-flag.svg");
}

@mixin argentina-flag-ico {
    background-image: url("../../images/flags/icon-argentina-flag.svg");
}

@mixin spain-flag-ico {
    background-image: url("../../images/flags/icon-spain-flag.svg");
}

@mixin india-flag-ico {
    background-image: url("../../images/flags/icon-india-flag.svg");
}

@mixin mexico-flag-ico {
    background-image: url("../../images/flags/icon-mexico-flag.svg");
}

@mixin united-kingdom-flag-ico {
    background-image: url("../../images/flags/icon-united-kingdom-flag.svg");
}

@mixin new-zealand-flag-ico {
    background-image: url("../../images/flags/icon-new-zealand-flag.svg");
}

@mixin singapore-flag-ico {
    background-image: url("../../images/flags/icon-singapore-flag.svg");
}

@mixin malaysia-flag-ico {
    background-image: url("../../images/flags/icon-malaysia-flag.svg");
}

@mixin south-korea-flag-ico {
    background-image: url("../../images/flags/icon-south-korea-flag.svg");
}

@mixin thailand-flag-ico {
    background-image: url("../../images/flags/icon-thailand-flag.svg");
}

@mixin vietnam-flag-ico {
    background-image: url("../../images/flags/icon-vietnam-flag.svg");
}

/* ----------------------------------- END: Edition Flag Mixins --------------------------------- */

/* ----------------------------- START: NBA Bet Mixins --------------------------------- */
@mixin nba-bet-logo {
    background-image: url("../../images/logos/nba-bet.png");
    background-repeat: no-repeat;
    background-size: contain;
}

@mixin dazn-logo {
    background-image: url("../../images/logos/dazn.svg");
    background-repeat: no-repeat;
    background-size: 100%;
}

@mixin sportsbet-logo {
    background-image: url("../../images/logos/tab.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

@mixin betmgm-logo {
    background-image: url("../../images/logos/betmgm.svg");
    background-repeat: no-repeat;
    background-size: 100%;
}

@mixin nba-left-banner {
    background-image: url("../../images/nba-bet-banner/left.png");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
}

@mixin nba-right-banner {
    background-image: url("../../images/nba-bet-banner/right.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
}

/* ----------------------------- END: NBA Bet Mixins --------------------------------- */

/* ----------------------------- START: NBA Mixins --------------------------------- */
@mixin nba-logo-bg {
    background-image: url("../../images/content/icon-nba-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

/* ----------------------------- END: NBA Mixins --------------------------------- */

/* ----------------------------- START: Nosso Futebol Mixins --------------------------------- */

@mixin mobile-nosso-futebol-assistir {
    background-image: url("../../images/football/nosso-futebol-assistir-mobile.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 22px;
    min-width: 139px;
}

@mixin desktop-nosso-futebol-assistir {
    background: url("../../images/football/nosso-futebol-assistir-desktop.svg");
    background-size: contain;
    height: 32px;
    min-width: 175px;
}

@mixin footer-tsn-logo-square() {
    background-image: url("../../images/sn-square-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin footer-nosso-futbol() {
    background-image: url("../../images/logos/nosso-futebol-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
}

/* ----------------------------- END: Nosso Futebol Mixins --------------------------------- */

/* ----------------------------- START: Footer Logo Mixins --------------------------------- */

@mixin tsn-korea-logo() {
    background-image: url("../../images/logos/tsn-korea.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}

/* ----------------------------- END: Footer Logo Mixins --------------------------------- */
